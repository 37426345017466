<template>
  <div id="page-profile">
    <v-container>
      <v-card>
        <v-card-title>{{
          $vuetify.lang.t('$vuetify.user.profile')
        }}</v-card-title>
        <v-card-text>
          <v-form ref="itemForm" lazy-validation v-model="valid">
            <v-row>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-avatar class="profile" size="200">
                      <v-img
                        :src="item.avatar ? item.avatar : 'static/img/av.png'"
                      ></v-img>
                    </v-avatar>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          :disabled="!item.avatar"
                          @click="deletePhoto(item)"
                        >
                          <v-icon>mdi-camera-off</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                        $vuetify.lang.t('$vuetify.different.deletePhoto')
                      }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col>
                    <v-file-input
                      show-size
                      :rules="[rules.maxSize10Mb]"
                      accept="image/png, image/jpeg, image/bmp"
                      :label="$vuetify.lang.t('$vuetify.different.photo')"
                      v-model="file"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="person"
                      autocomplete="off"
                      name="name"
                      :label="$vuetify.lang.t('$vuetify.user.name') + '*'"
                      :placeholder="$vuetify.lang.t('$vuetify.user.name')"
                      type="text"
                      required
                      counter
                      :rules="[rules.required, rules.max191]"
                      v-model="item.name"
                      :error="!!errors.name"
                      :error-messages="errors.name"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="person"
                      autocomplete="off"
                      name="surname"
                      :label="$vuetify.lang.t('$vuetify.user.surname') + '*'"
                      :placeholder="$vuetify.lang.t('$vuetify.user.surname')"
                      type="text"
                      required
                      counter
                      :rules="[rules.required, rules.max191]"
                      v-model="item.surname"
                      :error="!!errors.surname"
                      :error-messages="errors.surname"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="alternate_email"
                      autocomplete="off"
                      name="email"
                      :label="$vuetify.lang.t('$vuetify.login.email') + '*'"
                      :placeholder="$vuetify.lang.t('$vuetify.login.email')"
                      type="email"
                      required
                      counter
                      :rules="[rules.required, rules.email, rules.max191]"
                      v-model="item.email"
                      :error="!!errors.email"
                      :error-messages="errors.email"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="lock"
                      autocomplete="off"
                      name="password"
                      :label="$vuetify.lang.t('$vuetify.user.currentPassword')"
                      :placeholder="
                        $vuetify.lang.t('$vuetify.user.currentPassword')
                      "
                      :type="showCurrent ? 'text' : 'password'"
                      counter
                      v-model="item.current_password"
                      :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showCurrent = !showCurrent"
                      :error="!!errors.current_password"
                      :error-messages="errors.current_password"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="lock"
                      autocomplete="off"
                      name="password"
                      :label="$vuetify.lang.t('$vuetify.user.newPassword')"
                      :placeholder="
                        $vuetify.lang.t('$vuetify.user.newPassword')
                      "
                      :type="showPass ? 'text' : 'password'"
                      :rules="[rules.min8, rules.max191]"
                      counter
                      v-model="item.password"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPass = !showPass"
                      :error="!!errors.password"
                      :error-messages="errors.password"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="lock"
                      autocomplete="off"
                      name="password_confirmation"
                      :label="
                        $vuetify.lang.t('$vuetify.user.confirmNewPassword')
                      "
                      :placeholder="
                        $vuetify.lang.t('$vuetify.user.confirmNewPassword')
                      "
                      :type="showConfirm ? 'text' : 'password'"
                      :rules="[rules.min8, rules.max191, rules.confirmed]"
                      counter
                      v-model="item.password_confirmation"
                      :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showConfirm = !showConfirm"
                      :error="!!errors.password_confirmation"
                      :error-messages="errors.password_confirmation"
                    />
                  </v-col>
                  <v-col cols="12">
                    <small
                      >*{{
                        $vuetify.lang.t('$vuetify.different.indicate')
                      }}</small
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="!valid" @click="save">
            <v-icon left small>mdi-content-save</v-icon>
            {{ $vuetify.lang.t('$vuetify.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <AppLoader :dialog.sync="loading"></AppLoader>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      loading: false,
      valid: true,
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        min8: (v) =>
          !v ||
          (v && v.length >= 8) ||
          this.$vuetify.lang.t('$vuetify.validation.min8'),
        max191: (v) =>
          !v ||
          (v && v.length <= 193) ||
          this.$vuetify.lang.t('$vuetify.validation.max191'),
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v
          ) || this.$vuetify.lang.t('$vuetify.validation.email'),
        maxSize10Mb: (v) =>
          !v ||
          v.size < 10000000 ||
          this.$vuetify.lang.t('$vuetify.validation.maxSize10Mb'),
        confirmed: (v) =>
          v === this.item.password ||
          this.$vuetify.lang.t('$vuetify.validation.confirmed')
      },
      showCurrent: false,
      showPass: false,
      showConfirm: false,
      file: null,
      item: {
        id: null,
        name: null,
        surname: null,
        email: null,
        current_password: null,
        password: null,
        password_confirmation: null,
        avatar: null,
        created_at: null,
        updated_at: null,
        deleted_at: null
      },
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      errors: {
        id: null,
        name: null,
        surname: null,
        email: null,
        current_password: null,
        password: null,
        password_confirmation: null,
        avatar: null,
        created_at: null,
        updated_at: null,
        deleted_at: null
      },
      defaultErrors: {
        id: null,
        name: null,
        surname: null,
        email: null,
        current_password: null,
        password: null,
        password_confirmation: null,
        avatar: null,
        created_at: null,
        updated_at: null,
        deleted_at: null
      }
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.user.profile')
    }
  },
  watch: {
    item: {
      handler() {
        this.validation()
      },
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    }
  },
  methods: {
    init() {
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/profile/init',
          {},
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.item = response.data.data
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    save() {
      let t = this
      this.errors = Object.assign({}, this.defaultErrors)
      if (this.$refs.itemForm.validate()) {
        let formData = new FormData()
        if (this.file) formData.append('file', this.file)

        //Item
        for (let key in this.item) {
          if (this.item[key]) formData.append(key, this.item[key])
        }

        let headers = this.compAuthHeader
        headers.headers['Content-Type'] = 'multipart/form-data'

        this.loading = true

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/profile/update',
            formData,
            headers
          )
          .then((response) => {
            this.loading = false
            if (response.status === 200) {
              this.snackbar.show = true
              this.snackbar.color = 'green'
              this.snackbar.text = this.$vuetify.lang.t(
                '$vuetify.messages.saved'
              )
              this.item = response.data.data
              this.file = null
              this.$store.commit('setAuthUser', response.data.data)
            } else if (
              response.status === 422 &&
              typeof response.data.errors !== 'undefined'
            ) {
              for (let key in response.data.errors) {
                response.data.errors[key].forEach(function(item) {
                  t.errors[key] = item
                })
              }
            } else {
              this.snackbar.show = true
              this.snackbar.color = 'red'
              this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
            }
          })
          .catch((error) => {
            this.loading = false
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          })
      }
    },
    validation() {
      for (let keyItem in this.item) {
        if (this.errors[keyItem]) this.errors[keyItem] = null
      }
    },
    deletePhoto(item) {
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/profile/destroy-photo',
          {},
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t(
              '$vuetify.messages.deleted'
            )

            this.item = response.data.data
            this.$store.commit('setAuthUser', response.data.data)
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    }
  }
}
</script>
